import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Layout from './components/layout/layout.tsx'
import SendMoney from './pages/content/send-money/send-money.tsx'
import Header from './components/header/header.tsx';
import Footer from './components/footer/footer.tsx';
import { ContextProvider } from "./services/context_provider.tsx";


function App() {
  return (
    <div className="App" style={{overflowX:'hidden'}}>
      <BrowserRouter>
      <ContextProvider>
      <Header />
      <Routes>
        {/* Layout wraps all pages */}
        <Route path="/" element={<Layout />}>
          <Route index element={<SendMoney />} />
          {/* Add more routes as needed */}
        </Route>
      </Routes>
      <Footer />
       </ContextProvider>
    </BrowserRouter>
      
    </div>
  );
}

export default App;
