import React, { createContext, useState, useContext, ReactNode } from "react";

// Define a generic type for the context
interface ContextProviderProps<T> {
  children: ReactNode;
  initialData?: T;
}
interface ContextData {
    cardDetails: any[];
    transferData:any,
    recipeint: any;
  }
interface ContextValue<ContextData> {
  contextData: ContextData;
  setContextData: React.Dispatch<React.SetStateAction<ContextData>>;
}

// Create a context with a generic type
const ContextProviderContext = createContext<ContextValue<any> | undefined>(
  undefined
);

// Create a provider component
export function ContextProvider<T>({
  children,
  initialData,
}: ContextProviderProps<T>) {
  const [contextData, setContextData] = useState<T>(initialData as T);

  return (
    <ContextProviderContext.Provider value={{ contextData, setContextData }}>
      {children}
    </ContextProviderContext.Provider>
  );
}

// Custom hook to use the context
export function useContextProvider<T>() {
  const context = useContext(ContextProviderContext);
  if (!context) {
    throw new Error("useContextProvider must be used within a ContextProvider");
  }
  return context as ContextValue<T>;
}
