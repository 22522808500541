import React, { useState, useEffect } from 'react';
import './send-money.css';
import money from '../../assets/money.png'
import win from '../../assets/win.png'
import bb from '../../assets/bb.png'
import star from '../../assets/star.png'
import man from '../../assets/home_man.png'
import box from '../../assets/box.png'
import wave from '../../assets/vawe.png'
import pro_bac from '../../assets/Process.png'
import { FaCoffee } from 'react-icons/fa';
import usa from '../../assets/usa.png'
import { CiSearch } from 'react-icons/ci'; // Importing search icon
import { FaChevronDown } from 'react-icons/fa'; // Importing dropdown icon
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import MoneyTransfer from '../money-transfer/money-transfer.tsx'
// import img001 from '../../assets/img001.JPG'
// import img002 from '../../assets/img002.JPG'
import img001 from '../../assets/img01.png'
import img002 from '../../assets/img02.png'
import visa from '../../assets/visa.webp'
import master from '../../assets/master.webp'
import lankaPay from '../../assets/lankaPay.jpg'
import SlideShow from '../../../components/slideShow/slideShow.tsx'





const SendMoney = ({ }) => {
    const word = " credit/debit".split(""); // Split the word into an array of letters
    const [selectedCountry, setSelectedCountry] = useState('USA');  // Default country
    const [isOpen, setIsOpen] = useState(false);

    const countries = ['USA', 'CAN', 'UK', 'AUS', 'GRN'];

    const handleToggleDropdown = () => {
        setIsOpen(!isOpen);  // Toggle the dropdown state
    };

    const handleCountryChange = (country) => {
        setSelectedCountry(country);  // Set selected country
        setIsOpen(false);  // Close dropdown after selection
    };

    return (
        <>
            <section className='sec_1'>
                <div className='container mt-5 home_container_1'>
                    <div className='row'>
                        <div className='col-sm-12 col-md-7 col-lg-7 home-1-left'>

                            <div><span className='line_1'></span><span className='home_welcome'>Welcome to OneRemit 🎉</span></div>
                            <h4 className='home_1_head'>Send money with</h4>
                            <h4 className='home_1_head2'>
                                <span style={{ marginRight: '12px' }}>a</span>

                                <span className="animated-text">
                                    <span className="word credit">Credit</span>
                                    <span className="word debit">Debit</span>
                                </span>

                                <span style={{ marginLeft: '12px' }}>card</span>
                            </h4>

                            <p className='home_1_para mt-3'>
                                With OneRemit, you can easily send money using your credit or debit card in just a few clicks. Join over 1,000 users who trust OneRemit for fast and secure money transfers.
                            </p>
                            {/* <div className="d-flex justify-content-start align-items-center sec_1_start_button mt-5"> */}
                            <div className="sec_1_start_button mt-5">
                                <a href="https://app.oneid.ink/" target="_blank" rel="noopener noreferrer" className="link_text"> <button className='btn_common'>Get start</button></a>

                                {/* <a className='home_read_more'>  <span>Read more details</span></a> */}

                            </div>
                            <div className="row mt-4">
                                <div className="col-12">
                                    <img src={visa} alt="visa" className="card_home mx-2" />
                                    <img src={master} alt="master" className="card_home mx-2" />
                                    <img src={lankaPay} alt="lankaPay" className="card_home mx-2" />
                                </div>
                            </div>

                            <div className="home-money-container">
                                <img src={money} alt="money" className="home_money" />
                            </div>
                        </div>

                        <div className='col-sm-12 col-md-4 col-lg-4'>

                            <div className='convert_grid'>
                                <div className='row'>
                                    <div className='col-12'>
                                        <MoneyTransfer />

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-12 col-md-1 col-lg-1'>
                            <div className="home-money-container2">
                                <img src={money} alt="money" className="home_money" />
                            </div>
                        </div>
                    </div>
                </div>

            </section>


            <section className='sec_2'>
                {/* <div className='home_container_2'>
                    <div className='image_bac'>
                        <div className='row' style={{ height: '100vh' }}>
                            <div className='col-12 d-flex justify-content-center align-items-center text-center'>
                                <div>
                                    <h4 className='home_2_title'>Trusted secure money transfer</h4>
                                    <p className='home_2_p'>
                                        End-to-end payments and financial management in a single
                                        <br /> solution. Meet the right platform to help realize.
                                    </p>
                                    <a href="https://oneid.ink/" target="_blank" rel="noopener noreferrer" className="link_text">  <button className='btn_common2'>Get Started</button></a>

                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className='home_container_2 display_mobile'>
                    <div className='row'>
                        <div className='col-12'>
                            <h4 className='home_2_title'>Trusted secure money transfer</h4>
                            <p className='home_2_p'>
                                End-to-end payments and financial management in a single
                                <br /> solution. Meet the right platform to help realize.
                            </p>
                            {/* <a href="https://oneid.ink/" target="_blank" rel="noopener noreferrer" className="link_text">  <button className='btn_common2'>Get Started</button></a> */}
                        </div>
                    </div>

                </div>
            </section>

            <section className='sec_3'>
                <div className='container mobile_not'>
                    <div className="container_new sec_3_image_bac"></div>
                    <div className='col-sm-12 col-md-6 col-lg-6 sec_3_right'>
                        <div className='sec_3_box'>

                        </div>
                        <h5 className='sec_4_h1'>Send money</h5>
                        <h3 className='sec_4_h2'>Send money abroad to Sri Lanka in 3 easy steps
                        </h3>
                        <p className='sec_4_p1 mt-4'>
                            Looking for an app to send money? Send money, receive payments from abroad.
                        </p>
                    </div>
                </div>
                <div className='home_mobile container'>
                    <div className='row' >
                        <div className='col-sm-12 col-md-6 col-lg-6 sec_3_right'>

                            <h5 className='sec_4_h1'>Send money</h5>
                            <h3 className='sec_4_h2'>Send money abroad to Sri Lanka in 3 easy steps
                            </h3>
                            <p className='sec_4_p1 mt-4'>
                                Looking for an app to send money? Send money, receive payments from abroad.
                            </p>



                        </div>
                        <div className='col-sm-12 col-md-6 col-lg-6 mt-3'>
                            <SlideShow />

                        </div>
                    </div>
                </div>

            </section>

            <section className='sec_4'>
                <div className='home_container_2'>
                    <div className='row' >
                        <div className='col-sm-12 col-md-6 col-lg-6' style={{ paddingRight: '0' }}>
                            <div className='image_bac2'>
                                <img src={img001} alt="money" className="img001_resize" />
                            </div>
                        </div>
                        <div className='col-sm-12 col-md-6 col-lg-6 sec_4_right'>
                            <h5 className='sec_4_h1'>About our service</h5>
                            <h3 className='sec_4_h2'>We are Onepay, Leading payment gateway in sri lanka</h3>
                            <p className='sec_4_p1 mt-4'>Onepay, a registered business under Spemai (Pvt) Ltd (PV00206829) since 2018, supports over 3,000 businesses in Sri Lanka by providing secure, no-code payment solutions. With Onepay, businesses can easily manage all payments in one place without any technical expertise.
                            </p>
                            <p className='mt-2 key_benefits'>Key Benefits</p>
                            <div className='responsive_dec'>
                                <p className='sec_4_p1_sub'>- Secure internet payment gateway</p>
                                <p className='sec_4_p1_sub'>- Global reach with user-friendly features</p>
                                <p className='sec_4_p1_sub'>- Trusted protection for all transactions </p>
                            </div>

                            <div className='responsive_mob'>
                                <ul className='sec_4_list'>
                                    <li className='sec_4_p1_sub_li'>Secure internet payment gateway</li>
                                    <li className='sec_4_p1_sub_li'>Global reach with user-friendly features</li>
                                    <li className='sec_4_p1_sub_li'>Trusted protection for all transactions</li>
                                </ul>

                            </div>

                            {/* <a href="https://oneid.ink/" target="_blank" rel="noopener noreferrer" className="link_text"><button className='btn_common2 mt-4'>Get start</button></a>*/}
                            <p className='sec_4_p1 mt-2'>For more information, visit  <a className='home_read_more2' href="https://www.onepay.lk/index.html" target="_blank" rel="noopener noreferrer">  <span>Onepay.lk</span></a> </p>

                        </div>
                    </div>
                </div>

            </section>
            <section className='sec_5 mt-5'>
                <div className='home_container_2'>
                    <div className='row' >
                        <div className='col-sm-12 col-md-6 col-lg-6 sec_4_right'>
                            <h5 className='sec_4_h1'>Our Story</h5>
                            <h3 className='sec_4_h2'>We are inspired by the courage and sacrifice of our customers</h3>
                            <p className='sec_4_p1 mt-4'>We are inspired by the courage and sacrifice of Sri Lankan immigrants who leave their families behind to work abroad. These unsung heroes fulfill their promises to support their loved ones, improving their quality of life. We are committed to helping their hard-earned money go further, ensuring more reaches their families safely</p>

                            {/* <a href="https://oneid.ink/" target="_blank" rel="noopener noreferrer" className="link_text"><button className='btn_common2 mt-4'>Get start</button></a> */}

                        </div>
                        <div className='col-sm-12 col-md-6 col-lg-6 sec_4_image' style={{ paddingLeft: '0' }}>
                            {/* <div className='image_bac3'></div> */}
                            <div className='image_bac3'>
                                <img src={img002} alt="money" className="img002_resize" />
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <section className='sec_6 mt-5 mb-5'>
                <div className='container'>
                    <div className='sec_6_grid'>
                        <div className='row d-flex justify-content-start align-items-center'>
                            <div className='col-sm-12 col-md-3 col-lg-3 why_are'>
                                <span className='among'>Why choose us?</span>
                            </div>
                            <div className='col-sm-12 col-md-3 col-lg-3 why_are'>
                                <div className="d-flex justify-content-start align-items-center">
                                    <div className='sec_6_bac'>
                                        <img src={star} alt="money" className="sec_icon" />
                                    </div>
                                    <span className='sec_among_p'>24/7 customer<br></br> care </span>
                                </div>
                            </div>
                            <div className='col-sm-12 col-md-3 col-lg-3 why_are'>
                                <div className="d-flex justify-content-start align-items-center">
                                    <div className='sec_6_bac'>
                                        <img src={win} alt="money" className="sec_icon" />
                                    </div>
                                    <span className='sec_among_p'>ISO 27001<br></br> Certified </span>
                                </div>
                            </div>
                            <div className='col-sm-12 col-md-3 col-lg-3 why_are'>
                                <div className="d-flex justify-content-start align-items-center">
                                    <div className='sec_6_bac'>
                                        <img src={bb} alt="money" className="sec_icon" />
                                    </div>
                                    <span className='sec_among_p'>Instant <br></br>transfer </span>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </section>

            <section className='sec_7 mt-5'>
                <div className='image_bac_sec_7'>
                    <div className='row d-flex justify-content-start align-items-center'>

                        <div className='col-sm-12 col-md-8 col-lg-8'>
                            <div className="d-flex justify-content-start align-items-center">
                                <div className='ask_man'>
                                    <img src={man} alt="money" className="sec_icon_man" />
                                </div>
                                <div className="d-flex flex-column justify-content-center align-items-start ms-3">
                                    <span className='sec_green_p1'>Ask anything about OneID</span>
                                    <span className='sec_green_p2'>Ask anything about OneRemit Fast, Easy, Reliable.</span>
                                </div>
                            </div>

                        </div>
                        <div className='col-sm-12 col-md-4 col-lg-4 sec_7_btn' >
                            <a href="https://app.oneid.ink/" target="_blank" rel="noopener noreferrer" className="link_text"><button className='btn_common4'>Get start</button></a>
                            <a
                                href="https://wa.me/94760523025?text=Hi!%20I'm%20interested%20in%20your%20services%20and%20would%20love%20to%20know%20more%20about%20it.%20Could%20you%20please%20send%20me%20more%20information?%20Thank%20you!"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <button className='btn_common5'>Contact us</button>
                            </a>


                            {/* <button className='btn_common5'>Contact us</button> */}





                        </div>
                    </div>


                </div>
            </section>
        </>

    );
};

export default SendMoney;
