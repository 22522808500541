import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './slideShow.css';

const SimpleSlider = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: null, // Disable default arrows
        prevArrow: null, 
    };
    return (
        <div>
            <Slider {...settings}>
                <div className='slide_grid'>
                    <h4 className='number mb-3'>01</h4>
                    <h3 className="travel_name">Enter the amount to send in USD</h3>
                    <p className="slide_test mt-4">
                        Pay in USD using your Visa/Master debit or credit card.
                    </p>
                </div>
                <div className='slide_grid'>
                    <h4 className='number mb-3'>02</h4>
                    <h3 className="travel_name">Select the person you want to send money to and choose Commercial Bank in Sri Lanka
                    </h3>
                    <p className="slide_test mt-4">
                        Pay in USD using your Visa/Master debit or credit card.
                    </p>
                </div>
                <div className='slide_grid'>
                    <h4 className='number mb-3'>03</h4>
                    <h3 className="travel_name">Send USD, receive LKR
                    </h3>
                    <p className="slide_test mt-4">
                    The recipient will receive LKR directly from OneRemit at the best available rate.
                    </p>
                </div>
            </Slider>
        </div>
    );
}

export default SimpleSlider;
