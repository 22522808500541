import axios from "axios";
import { base_url } from "../../src/environment/environment.tsx";

const API_URL = base_url;
class ApiResponse {
  code: any;
  message: string;
  data?: any;
  constructor(success: any, message: string, data: any = null) {
    this.code = success;
    this.message = message;
    this.data = data;
  }
}





export const getCurrencyConvert = async (
  amount: any,
  from_currency: any,
  to_currency: any
): Promise<ApiResponse> => {
//   const token = localStorage.getItem("token");
//   if (!token) {
//     throw new Error("Token not found");
//   }
  try {
    const response = await axios.get(
      `${API_URL}currency/convert/?amount=${amount}&from_currency=${from_currency}&to_currency=${to_currency}`,
    //   {
    //     headers: {
    //       Authorization: `Bearer ${token}`,
    //     },
    //   }
    );
    console.log(response.data);
    return new ApiResponse(100, response?.data?.message, response.data);
  } catch (error: any) {
    // if (error.response?.status === 401) {
    //   window.location.href = "/login";
    // }
    const errorMessage =
      error.response?.data?.message || "An unknown error occurred";
    return new ApiResponse(105, errorMessage);
  }
};


