import React from 'react';
import './footer.css';
import OneId from '../../pages/assets/oneid2.png'
import facebookLogo from '../../pages/assets/footerS/facebook.png';
import instagramLogo from '../../pages/assets/footerS/instagram.png';
import twitterLogo from '../../pages/assets/footerS/twitter.png';
import linkedinLogo from '../../pages/assets/footerS/linkedin.png';
import whatsappLogo from '../../pages/assets/footerS/whatsapp1.png';

function Footer() {
  return (
    <footer className="footer">
      <div className="container footer__top">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 footer__top-section">
            <div className='padding_sec'>
              <img src={OneId} alt="onepay-logo" className="footer__logo" />
              {/* <p className="footer__paragraph2">Spemai Pte Ltd</p>
              <p className="footer__paragraph2">Global HQ - Singapore</p>
              <p className="footer__paragraph2">68 Circular Road, #02-01, 049422, Singapore</p> */}
              <p className="footer__paragraph3 mt-2">info@onepay.lk</p>
              <p className="footer__paragraph">
                Onepay is an intellectual property of Spemai (PVT) Ltd, a company incorporated and duly registered under the laws and regulations of Sri Lanka and is governed by Central Bank of Sri Lanka.
                PV Number PV00206829

              </p>
            </div>

          </div>

          <div className="col-lg-2 col-md-2 col-sm-12 footer__top-section">
            <h3 className="footer__links_heading special_mobile">Links</h3>
            {/* <div><a className="footer_a_tag" target="_parent">About Us</a></div>
            <div><a download className="footer_a_tag" target="_blank">User Terms & Conditions</a></div> */}
            {/* <div><a href="./agreement.html" className="footer_a_tag" target="_blank">Merchant Terms & Conditions</a></div> */}
            <div><a href="https://www.onepay.lk/spemai_privacy_policy.html" className="footer_a_tag" target="_blank">Privacy Policy</a></div>
          </div>

          <div className="col-lg-3 col-md-3 col-sm-12 footer__top-section">
            <h3 className="footer__links_heading">Contact us</h3>
            <p className="footer__paragraph">
              3rd Floor, 292,Richmond House, <br /> Gamsabha Junction,High Level Road,<br />
              Nugegoda, Sri Lanka.
            </p>
            <a href="tel:+94 11 7 021 540" className="footer__paragraph">+94 76 052 3025</a> <br />
            <a href="tel:+94 11 702 1540" className="footer__paragraph">+94 11 702 1540</a>
          </div>
          <div className="col-lg-1 col-md-1 col-sm-12 footer__top-section">
           
            <div className="footer-logo-section">


              <ul className="footer-socialmedia">
                <li className="footer-socialmedia__item">
                  <a href="https://www.facebook.com/OneRemitjp/" target="_blank" rel="noopener noreferrer" className="footer-socialmedia__item-link">
                    <img src={facebookLogo} alt="facebook-logo" />
                  </a>
                </li>
                <li className="footer-socialmedia__item">
                  <a href="https://www.instagram.com/oneremitjp?igsh=MW40OW9yaGNtN2ZtZA==" target="_blank" rel="noopener noreferrer" className="footer-socialmedia__item-link">
                    <img src={instagramLogo} alt="instagram-logo" />
                  </a>
                </li>
                
                <li className="footer-socialmedia__item">
                  <a href="https://whatsapp.com/channel/0029VagIYCs545uuFfwIdz2w" target="_blank" rel="noopener noreferrer" className="footer-socialmedia__item-link">
                    <img style={{ width: '15px' }} src={whatsappLogo} alt="whatsapp-logo" />
                  </a>
                </li>
              </ul>
            </div>
          </div>



        </div>
      </div>

      <div className="footer__copyright_text">
        <div className="footer__horizontal_line"></div>
        <p className="footer__copyrigh_paragraph">© 2024 Copyright by Onepay. All rights reserved.</p>
      </div>
    </footer>
  );
}

export default Footer;
