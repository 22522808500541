import { useState, useEffect, useRef } from "react";
import React from 'react';
import "./header.css";
import { Link } from "react-router-dom";
import Oneid from "../../pages/assets/oneid.png";

import { useNavigate } from "react-router-dom";



function Header() {
    useEffect(() => {

    }, []);


    return (
        <header className="navbar navbar-expand-lg">
            <div className="container-fluid">
                <Link style={{ textDecoration: "none" }} to="/" className="navbar-brand">
                    <img
                        style={{ width: "80px", height: "auto" }}
                        className="img"
                        src={Oneid}
                        alt="Spemai Logo"
                    />
                </Link>
                <a href="https://app.oneid.ink/" target="_blank" rel="noopener noreferrer">
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarContent"
                        aria-controls="navbarContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"

                    >
                        Register
                    </button>
                </a>
                <a href="https://app.oneid.ink/login" target="_blank" rel="noopener noreferrer" className="link_text login_responsive">
                                <span className="log_in">Login</span>
                            </a>
                <div className="collapse navbar-collapse" id="navbarContent">
                    <ul className="navbar-nav ms-auto">
                        <li className="nav-item">
                            <a href="https://app.oneid.ink/login" target="_blank" rel="noopener noreferrer" className="link_text">
                                <span className="log_in">Login</span>
                            </a>

                            <a href="https://app.oneid.ink/" target="_blank" rel="noopener noreferrer">
                                <button className="logout">
                                    Register
                                </button>
                            </a>

                        </li>
                    </ul>
                </div>
            </div>
        </header>

    );
}

export default Header;
