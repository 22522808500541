import React, { useCallback, useEffect, useState } from "react";
import "./money-transfer.css";
// import { getNames } from "country-list";

import { Outlet, useNavigate } from "react-router-dom";
import { useContextProvider } from "../../../services/context_provider.tsx";
import {
  getCurrencyConvert,

} from "../../../services/money_transfer.tsx";
import debounce from "lodash.debounce";
import toast from "react-hot-toast";
import sl_flag from "../../../pages/assets/sl-flag.webp";
import lkr_flag4 from "../../../pages/assets/Ellipse4.png";
import lkr_flag3 from "../../../pages/assets/Ellipse3.png";
import lkr_flag from "../../../pages/assets/Ellipse2.png";
import usd_flag from "../../../pages/assets/Ellipse1.png"

export default function MoneyTransfer() {
  const { contextData, setContextData } = useContextProvider<any>();

  const navigate = useNavigate();
  const [covertedCurrencyData, setCovertedCurrencyData] = useState<any>();
  const [error, setError] = useState<string | null>(null);
  const [moneyTrnsferData, setMoneyTransferData] = useState({
    card_id:
      contextData?.transferData !== undefined
        ? contextData?.transferData?.card_id
        : "",
    from_currency_code:
      contextData?.transferData?.from_currency_code !== undefined
        ? contextData?.transferData?.from_currency_code
        : "USD",
    to_currency_code:
      contextData?.transferData?.to_currency_code !== undefined
        ? contextData?.transferData?.to_currency_code
        : "LKR",
    amount:
      contextData?.transferData?.amount !== undefined
        ? contextData?.transferData?.amount
        : 10,
    converted_amount: "",
    transaction_narration:
      contextData?.transferData?.transaction_narration !== undefined
        ? contextData?.transferData?.transaction_narration
        : "",
  });
  const [moneyConvertLoading, setMoneyConvertLoading] = useState(false);

  useEffect(() => {
   
    console.log("Transfer Details:", contextData?.cardDetails);
   
  }, [contextData]);
  const convertSendAmount = useCallback(
    debounce(
      async (amount, to_currency_code, from_currency_code) => {
        setMoneyConvertLoading(true); // Set loading to true at the beginning
        console.log(amount, 'amountamount');
  
        try {
          setError(null); // Reset any existing error
  
          // Validate the amount before calling the API
          if (amount < 5) {
            setError("Minimum transaction amount must be 5$");
            setCovertedCurrencyData(0);
            return; // Exit if validation fails
          }
  
          if (amount > 2500) {
            setError("Maximum transaction amount must be 2500$");
            setCovertedCurrencyData(0);
            return; // Exit if validation fails
          }
  
          if (moneyTrnsferData?.amount === "0") {
            toast.error("Transfer amount can't be zero");
            setCovertedCurrencyData(0);
            return; // Exit if validation fails
          }
  
          if (moneyTrnsferData?.converted_amount === "0") {
            toast.error("Transfer amount can't be zero");
            setCovertedCurrencyData(0);
            return; // Exit if validation fails
          }
  
          // Call the API to convert the currency
          const moneyConvertRes = await getCurrencyConvert(
            amount,
            from_currency_code,
            to_currency_code
          );
  
          // Reset converted currency data
          setCovertedCurrencyData(0); 
  
          if (moneyConvertRes.code === 100) {
            console.log(moneyConvertRes, 'moneyConvertRes');
            setCovertedCurrencyData(moneyConvertRes?.data);
  
            // Update the money transfer data with the converted amount
            setMoneyTransferData((prevState) => ({
              ...prevState,
              converted_amount: moneyConvertRes?.data?.original_amount,
            }));
          }
        } catch (error) {
          console.error("Error converting amount:", error);
          setError("An error occurred while converting the amount."); // Set an error message
        } finally {
          setMoneyConvertLoading(false); // Always set loading to false in the finally block
        }
      },
      1000 // Debounce time
    ),
    []
  );
  
  

  // const convertSendAmount = useCallback(
  //   debounce(
  //     async (amount, to_currency_code, from_currency_code) => {
  //     console.log(amount,'amountamount')

     
  //     try {
  //       console.log(amount,'amouuuuu1234')
  //         setError(null); 
  
          
  //         const moneyConvertRes = await getCurrencyConvert(
  //             amount,
  //            from_currency_code,
  //             to_currency_code
  //         );
  
  //         setCovertedCurrencyData(0); 
  
  //         if (moneyConvertRes.code === 100) {
            
  //             console.log(moneyConvertRes, 'moneyConvertRes');
  //             setCovertedCurrencyData(moneyConvertRes?.data);
  //             console.log(moneyTrnsferData.amount,'amount 2')
             
  //             if(amount >= 5 && amount <= 2500){
  //               if (amount < 5) {
  //                 setError("Minimum transaction amount must be 5$");
  //                 setCovertedCurrencyData(0);
  //                 return;
  //               }
  //               if (amount > 2500) {
  //                 setError("Maximum transaction amount must be 2500$");
  //                 setCovertedCurrencyData(0);
  //                 return;
  //               }
  //               if (moneyTrnsferData?.amount === "0") {
  //                 toast.error("Transfer amount can't be zero");
  //                 setCovertedCurrencyData(0);
  //                 return;
  //               }
  //               if (moneyTrnsferData?.converted_amount === "0") {
  //                 toast.error("Transfer amount can't be zero");
  //                  setCovertedCurrencyData(0);
  //                 return;
  //               }
               
  //               setMoneyTransferData((prevState) => ({
  //                 ...prevState,
  //                 converted_amount: moneyConvertRes?.data?.original_amount,
  //             }
  //           ));
  
  
  //             }else{

  //               setCovertedCurrencyData(0);
  //             }
             
  //             setMoneyConvertLoading(false); // Assuming this indicates loading state completion
             
  //         }
  //     } catch (error) {
  //         console.error("Error converting amount:", error);
  //         setError("An error occurred while converting the amount."); // Set an error message
  //     }
  //     finally {
  //     }
  //   }, 1000),
  //   []
  // );
  const goToChild = () => {
    if (moneyTrnsferData?.card_id === "") {
      toast.error("Select your card");
      return;
    }
    if (moneyTrnsferData?.amount < 5) {
      setError("Minimum transaction amount must be 5$");
      setCovertedCurrencyData(0);
      return;
    }
    if (moneyTrnsferData?.amount > 2500) {
      setError("Maximum transaction amount must be 2500$");
      setCovertedCurrencyData(0);
      return;
    }
    if (moneyTrnsferData?.amount === "0") {
      toast.error("Transfer amount can't be zero");
      setCovertedCurrencyData(0);
      return;
    }
    if (moneyTrnsferData?.converted_amount === "0") {
      toast.error("Transfer amount can't be zero");
       setCovertedCurrencyData(0);
      return;
    }
    setContextData((prevState: any) => ({
      ...prevState,
      transferData: moneyTrnsferData,
    }));
    navigate(`/set-favourite`);
  };
  const test = async (  amount, to_currency_code,
    from_currency_code
    ) => {
    try {
      console.log(amount,'amouuuuu1234')
        setError(null); // Reset any existing error

        // Assuming amount, from_currency_code, and to_currency_code are defined in the scope
        const moneyConvertRes = await getCurrencyConvert(
            amount,
           from_currency_code,
            to_currency_code
        );

        setCovertedCurrencyData(0); // Reset converted currency data

        if (moneyConvertRes.code === 100) {
          
            console.log(moneyConvertRes, 'moneyConvertRes');
            setCovertedCurrencyData(moneyConvertRes?.data);
            console.log(moneyTrnsferData.amount,'amount 2')
            // Update the money transfer data with the converted amount
            if(moneyTrnsferData.amount >= 5 && moneyTrnsferData.amount <= 2500){
             
              setMoneyTransferData((prevState) => ({
                ...prevState,
                converted_amount: moneyConvertRes?.data?.original_amount,
            }));


            }else{
              setCovertedCurrencyData(0);
            }
           
            setMoneyConvertLoading(false); // Assuming this indicates loading state completion
           
        }
    } catch (error) {
        console.error("Error converting amount:", error);
        setError("An error occurred while converting the amount."); // Set an error message
    }
};

  useEffect(() => {
    if (moneyTrnsferData?.amount < 5) {
      setError("Minimum transaction amount must be 5$");
      setCovertedCurrencyData(0);
      // return;
    }
    if (moneyTrnsferData?.amount > 2500) {
      setError("Maximum transaction amount must be 2500$");
      setCovertedCurrencyData(0);
      // return;
    }
    if (moneyTrnsferData?.amount > 2500) {
      setError("Maximum transaction amount must be 2500$");
      setCovertedCurrencyData(0);
      // return;
    }
    
    // test(moneyTrnsferData?.amount,
    //   moneyTrnsferData?.to_currency_code,
    //   moneyTrnsferData?.from_currency_code)
    
    convertSendAmount(
      moneyTrnsferData?.amount,
      moneyTrnsferData?.to_currency_code,
      moneyTrnsferData?.from_currency_code
    );
  }, [
    moneyTrnsferData?.amount,
    moneyTrnsferData?.to_currency_code,
    moneyTrnsferData?.from_currency_code,
  ]);

  const handleSelectCardHandle = (e: any) => {
    if (e.target.value === "add") {
    }
    console.log(e.target.value);
    setMoneyTransferData((prevState) => ({
      ...prevState,
      card_id: e.target.value, // Replace "new-card-id" with the actual value you want to set
    }));
  };
  const handleFromCurrencyCodeHandle = (e: any) => {
    console.log(e.target.value);
    setMoneyTransferData((prevState) => ({
      ...prevState,
      from_currency_code: e.target.value, // Replace "new-card-id" with the actual value you want to set
    }));
  };

  const handleToCurrencyCodeHandle = (e: any) => {
    console.log(e.target.value);
    setMoneyTransferData((prevState) => ({
      ...prevState,
      to_currency_code: e.target.value, // Replace "new-card-id" with the actual value you want to set
    }));
  };
  const handleSendAmountHandle = (e: any) => {
    
    console.log(e.target.value,'amounnnn');
    setMoneyTransferData((prevState) => ({
      ...prevState,
      amount: e.target.value, 
    }));
    const inputValue = parseFloat(e.target.value);
    // console.log(e.target.value ,'e.target.value')
    // if (inputValue >= 5 && inputValue <= 2500) {
    //   setMoneyTransferData((prevState) => ({
    //     ...prevState,
    //     amount: e.target.value, 
    //   }));
    // }
    // else{
    //   setMoneyTransferData((prevState) => ({
    //     ...prevState,
    //     amount: 0, 
    //   }));
   
    // }
    console.log(e.target.value,'amounnnn');
   
  };
  

  const handleConvertedAmountHandle = (e: any) => {
    this.goToChild()
    console.log(e.target.value);
    setMoneyTransferData((prevState) => ({
      ...prevState,
      converted_amount: e.target.value, // Replace "new-card-id" with the actual value you want to set
    }));
  };
  const handleNoteTextHandle = (e: any) => {
    console.log(e.target.value);
    setMoneyTransferData((prevState) => ({
      ...prevState,
      transaction_narration: e.target.value, // Replace "new-card-id" with the actual value you want to set
    }));
  };

  return (
    <div className="main-money-transfer-div">
      <div className="d-flex justify-content-center align-items-center pt-4">
        <div className="money-transfer-main-div">
          <div >
            <div className="label-text">Send amount</div>
            <div className="div-type-input-money">
              <div className="d-flex justify-content-start align-items-center">
               
                <img src={usd_flag} width={24} alt="" className="mr-2" style={{marginRight:'6px'}}/>
                <div
                  style={{
                    fontSize: "14px",
                    cursor: "pointer",
                    border: "none",
                    width: "100%",
                    outline: "none",
                    backgroundColor: "#F8F8F8",
                  }}
                >
                  USD
                </div>
              </div>
              <input
                placeholder="Amount"
                className="send-vale-text"
                type="number"
                style={{
                  border: "none",
                  width: "50%",
                  outline: "none",
                  textAlign: "end",
                  backgroundColor: "#F8F8F8",
                }}
                value={moneyTrnsferData?.amount}
                onChange={handleSendAmountHandle}
                inputMode="decimal"
                pattern="[0-9]*[.,]?[0-9]*"
              />
            </div>
          </div>
          <div className="formik-errors vw-1001" style={{ textAlign: "start" }}>
            {error}
          </div>
          <div className="d-flex justify-content-between align-items-center vw-1001">
            <div className="details-text">Card fees</div>
            <div className="details-text-value">
              {covertedCurrencyData?.platform_charge
                ? covertedCurrencyData?.platform_charge
                : "0"}{" "}
              USD
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center vw-1001">
            <div className="details-text">Instant transfer fee</div>
            <div className="details-text-value">
              {covertedCurrencyData?.instant_charge
                ? covertedCurrencyData?.instant_charge
                : "0"}{" "}
              USD
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center vw-1001">
            <div className="details-text">Total fees</div>
            <div className="details-text-value">
              {covertedCurrencyData?.platform_charge &&
              covertedCurrencyData?.platform_charge
                ? covertedCurrencyData?.platform_charge +
                  covertedCurrencyData?.instant_charge
                : "0"}{" "}
              USD
            </div>
          </div>
          <div className="devider"></div>
          <div className="d-flex justify-content-between align-items-center vw-1001">
            <div className="details-text">Exchange rate</div>
            <div className="details-text-value">
              {covertedCurrencyData?.usd_rate
                ? covertedCurrencyData?.usd_rate
                : "0"}{" "}
              LKR
            </div>
          </div>
          <div className="vw-1001 mb-3">
            <div className="label-text">Recipient get</div>
            <div className="div-type-input-money">
              <div className="d-flex justify-content-start align-items-center">
               
                <img src={sl_flag} width={28} alt="" className="mr-2" style={{marginRight:'6px'}}/>
               
                <div
                  style={{
                    fontSize: "14px",
                    cursor: "pointer",
                    border: "none",
                    width: "100%",
                    outline: "none",
                    backgroundColor: "#F8F8F8",
                  }}
                >
                  LKR
                </div>
              </div>
              <div className="send-vale-text">
                {moneyConvertLoading ? (
                  <>
                    <div className="details-text">Converting...</div>
                  </>
                ) : (
                  <>
                    {covertedCurrencyData?.converted_amount_receiver_amount_lkr
                      ? covertedCurrencyData?.converted_amount_receiver_amount_lkr.toFixed(
                          2
                        )
                      : "0"}
                  </>

                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
